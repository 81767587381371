import { createUseStyles } from 'react-jss';
import {
  backgroundSubtle01,
  borderSubtle01,
  heading02,
  textPrimary,
  textSecondary,
} from '../../../theme';

export default createUseStyles(
  {
    root: {
      color: textPrimary,
      backgroundColor: backgroundSubtle01,
      borderRadius: 4,
      maxWidth: 250,
      minWidth: 200,
      boxShadow: '0px 4px 12px 0px #00000014',
      ['@media (max-width: 768px)']: {
        maxWidth: '90vw',
      },
    },

    name: {
      ...heading02,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },

    title: {
      marginTop: 3,
      color: textSecondary,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },

    icon: {
      boxSizing: 'border-box',
      height: 24,
      width: 24,
      padding: 3,
    },

    doctor: {
      userSelect: 'none',
      '&$item': {
        padding: 24,
        display: 'block',
      },
    },

    item: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      width: '100%',
      boxSizing: 'border-box',
      padding: [20, 24],

      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        height: 1,
        backgroundColor: borderSubtle01,
        bottom: 0,
      },

      '&:last-child:after': {
        display: 'none',
      },
    },
    text: {
      marginLeft: 8,
    },
  },
  {
    name: 'ProfileMenu',
  },
);
