import normalize_jss from 'normalize-jss';
import { createUseStyles } from 'react-jss';

import {
  backgroundInverse,
  backgroundSubtle00,
  baseFont,
  CustomCiamUiTheme,
  linkColor,
} from '../../../theme';

export const gradient = 'linear-gradient(292.42deg, #004B70 0%, #009ACE 100%)';

export const ciamPageStyles = createUseStyles(
  {
    ...normalize_jss,

    '@global html': {
      background: backgroundInverse,
    },

    '@global body': {
      ...baseFont,
      background: backgroundSubtle00,
      position: 'relative',
    },

    root: {
      '& button, & input': {
        fontFamily: 'inherit',
      },

      '& a': {
        color: linkColor,
        textDecoration: 'none',
      },

      '& a:hover': {
        textDecoration: 'underline',
      },
    },
  },
  {
    name: 'CssBaseline-ciam',
  },
);

export const loginPageStyles = createUseStyles(
  {
    ...normalize_jss,

    '@global html': {
      // TODO theme
      background: ({ theme }: { theme: CustomCiamUiTheme }) =>
        theme.backgroundColor,
      minHeight: '100vh',
    },

    '@global body': {
      position: 'relative',
      background: ({ theme }: { theme: CustomCiamUiTheme }) =>
        theme.backgroundColor,
      minHeight: '100vh',
    },

    root: {
      all: 'initial',
      ...baseFont,

      '& button, & input': {
        fontFamily: 'inherit',
      },

      '& a': {
        color: linkColor,
        textDecoration: 'none',
      },

      '& a:hover': {
        textDecoration: 'underline',
      },
    },
  },
  {
    name: 'CssBaseline-loginPages',
  },
);
