import * as React from 'react';
import { Typography } from '../Typography';
import { ciamPageStyles, loginPageStyles } from './CssBaseline.styles';
import { useTheme } from 'react-jss';
import { CustomCiamUiTheme } from '../../../theme';
import { IBusinessUnit } from '../../../utils/businessUnitMappingHelpers/businessUnitMappingHelpers';
export interface ICssBaseline {
  variant?: 'login-page' | 'ciam';
  businessUnit?: IBusinessUnit['type'];
}

const CssBaseline: React.FC<React.PropsWithChildren<ICssBaseline>> = ({
  children,
  variant,
}) => {
  const theme = useTheme<CustomCiamUiTheme>();
  const classes =
    variant === 'ciam' ? ciamPageStyles() : loginPageStyles({ theme });
  return (
    <Typography tag="div" className={classes.root}>
      {children}
    </Typography>
  );
};

export default CssBaseline;
