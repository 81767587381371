import clsx from 'clsx';
import { motion } from 'framer-motion';
import * as React from 'react';
import { Clickable, Icon } from '../../../atoms';
import useStyles from './DropdownOpener.styles';

export interface IDropdownOpenerProps {
  handle: React.ReactNode;
  children: React.ReactNode;
  position?: 'left' | 'right';
  target?: HTMLElement;
}

const DropdownOpener: React.FC<IDropdownOpenerProps> = ({
  handle,
  children,
  position = 'left',
}) => {
  const classes = useStyles();
  const node = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);

  const toggle = (newOpen?: boolean) =>
    setOpen((o) => (newOpen ? newOpen : !o));

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
      if (open && node.current && !node.current.contains(e.target as Node)) {
        toggle(false);
      }
    };

    document.body.addEventListener('click', handleClickOutside);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [open]);

  return (
    <div className={clsx(classes.root, classes[position])} ref={node}>
      <Clickable
        onClick={toggle.bind(null, undefined)}
        className={classes.handle}
      >
        <div className={classes.userTitle}>{handle}</div>
        <div className={classes.chevron}>
          <motion.div animate={{ scaleY: open ? -1 : 1 }}>
            <Icon type="chevronDown" />
          </motion.div>
        </div>
      </Clickable>
      <motion.div
        initial={{ opacity: 0 }}
        animate={
          open
            ? { opacity: 1, pointerEvents: 'all' }
            : { opacity: 0, transition: { duration: 0.1 } }
        }
        className={classes.dropdown}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default DropdownOpener;
