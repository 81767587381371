import { createUseStyles } from 'react-jss';
import { label01, textSecondary } from '../../../theme';

export default createUseStyles(
  {
    root: {
      ...label01,
      color: textSecondary,
      textAlign: 'center',
      padding: 30,

      '& a': {
        textDecoration: 'underline',
      },
    },
  },
  {
    name: 'Footer',
  },
);
