import { createUseStyles } from 'react-jss';

export default createUseStyles(
  {
    root: {
      height: '100%',
      position: 'relative',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },

    handle: {
      display: 'flex',
      width: '100%',
      justifyContent: 'end',
      ['@media (max-width: 768px)']: {
        justifyContent: 'space-between',
      },
    },

    chevron: {
      marginLeft: 12,
      width: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    left: {
      '& $dropdown': {
        left: -10,
      },
    },
    right: {
      '& $dropdown': {
        right: -10,
        ['@media (max-width: 768px)']: {
          right: 'inherit',
        },
      },
    },
    dropdown: {
      position: 'absolute',
      top: '100%',
      zIndex: 10,
      pointerEvents: 'none',
    },
    userTitle: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  {
    name: 'DropdownOpener',
  },
);
