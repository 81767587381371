import * as React from 'react';
import useStyles from './Footer.styles';

export interface IFooterProps {
  children: React.ReactNode;
}

const Footer: React.FC<IFooterProps> = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default Footer;
