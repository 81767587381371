import React from 'react';

export const Logo = () => (
  <svg
    height="100%"
    viewBox="0 0 57 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.9228 0C23.8679 0 23.015 0.866528 23.015 1.93185C23.015 2.99717 23.8679 3.86369 24.9228 3.86369C25.9777 3.86369 26.8357 2.99717 26.8357 1.93185C26.8357 0.866528 25.9777 0 24.9228 0Z"
      fill="currentColor"
    />
    <path
      d="M45.6616 6.36504H48.5984L48.6438 7.59828C48.9112 7.25685 50.0213 5.98794 52.0145 5.98794C55.2389 5.98794 57 8.00087 57 11.512V19.6963H53.9522V11.614C53.9522 9.80487 52.8572 8.77037 51.3232 8.77037C49.7892 8.77037 48.7043 9.80487 48.7043 11.614V19.6963H45.6565V6.36504H45.6616ZM19.9369 19.6963H16.884V0.321136H19.9369V19.7014V19.6963ZM26.4564 19.6963H23.4035V6.36504H26.4564V19.6963ZM10.4049 6.36504H13.4225V19.6963H10.4049L10.3595 18.3C9.80444 18.8707 8.331 20.0734 6.26717 20.0734C2.66935 20.0734 0 17.1483 0 13.0307C0 8.67355 2.88633 5.99303 6.26717 5.99303C8.63376 5.99303 9.96592 7.29252 10.3595 7.77155L10.4049 6.37014V6.36504ZM3.11845 13.0358C3.11845 15.594 4.67263 17.3011 6.82728 17.3011C8.78514 17.3011 10.5462 15.859 10.5462 13.0358C10.5462 10.2126 8.86084 8.77037 6.82728 8.77037C4.5818 8.77037 3.11845 10.5795 3.11845 13.0358ZM39.3187 13.0358C39.3187 10.2533 37.5829 8.77037 35.6099 8.77037C33.2483 8.77037 31.8909 10.7069 31.8909 13.0358C31.8909 15.4921 33.3543 17.3011 35.6099 17.3011C37.6838 17.3011 39.3187 15.7418 39.3187 13.0358ZM42.1899 18.3815C42.1899 24.0993 38.8746 26.0001 34.9741 26.0001C32.3703 26.0001 30.801 25.2051 30.251 24.9452L31.0079 22.2953C31.351 22.4787 32.779 23.3298 34.9488 23.3298C37.1186 23.3298 39.1471 21.857 39.1471 19.2122V18.2898C38.8999 18.5497 37.6081 20.0734 35.0245 20.0734C31.4671 20.0734 28.7826 17.1839 28.7826 13.0307C28.7826 8.87739 31.3106 5.99303 35.0397 5.99303C37.5829 5.99303 38.8696 7.4505 39.137 7.77155L39.1774 6.37014H42.2V18.3866L42.1899 18.3815Z"
      fill="currentColor"
    />
  </svg>
);
