import { Trans } from '@lingui/react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import * as React from 'react';

import { routes } from '../../../routes';
import { Clickable, CssBaseline, Icon } from '../../atoms';
import { Footer } from '../../atoms/Footer';
import { Header } from '../../molecules/Header';
import { DropdownOpener } from '../../molecules/Header/DropdownOpener';
import { ProfileMenu } from '../../molecules/ProfileMenu';
import useStyles from './BasePageTemplate.styles';
import { signOut } from 'next-auth/react';
import { IBusinessUnit } from '../../../utils/businessUnitMappingHelpers/businessUnitMappingHelpers';

export interface IDoctor {
  name: React.ReactNode;
  title: React.ReactNode;
}

interface IBasePageTemplateBaseProps {
  page: string;
  doctor?: IDoctor;
  variant?: 'bordered';
  isLogoClickable?: boolean;
  back?(): void;
  privacyLink?: string;
  termsOfUseLink?: string;
  subtitle?: React.ReactNode;
  collapsed?: boolean;
}

interface IBasePageTemplateSplashProps {
  title?: never;
  splash: true;
}

interface IBasePageTemplateCommonProps {
  title: React.ReactNode;
  splash?: never;
}

export type IBasePageTemplateProps = IBasePageTemplateBaseProps &
  (IBasePageTemplateSplashProps | IBasePageTemplateCommonProps);

const BasePageTemplate: React.FC<
  React.PropsWithChildren<IBasePageTemplateProps>
> = ({
  children,
  page,
  title,
  splash,
  isLogoClickable = true,
  subtitle,
  doctor,
  variant,
  collapsed,
  back,
  privacyLink = process.env.NEXT_PUBLIC_PRIVACY_URL ||
    'https://www.invisalign.com/global/privacy-policy',
  termsOfUseLink = process.env.NEXT_PUBLIC_TERMS_OF_USE_URL ||
    'https://www.invisalign.com/global/terms-of-use',
}) => {
  const classes = useStyles();
  const { push, query } = useRouter();
  const handleLogout = async () => {
    await signOut().then(() => {
      window.location.href = `/api/auth/${query.businessUnit}/logged-out`;
    });
  };

  return (
    <CssBaseline
      variant="ciam"
      businessUnit={query.businessUnit as IBusinessUnit['type']}
    >
      <div
        className={clsx(
          classes.root,
          variant === 'bordered' && classes.bordered,
          collapsed && classes.collapsed,
        )}
      >
        <Header
          onLogosClick={
            isLogoClickable
              ? () =>
                  push({
                    pathname: routes.root(),
                    query: { businessUnit: query.businessUnit },
                  })
              : undefined
          }
          profile={
            doctor ? (
              <DropdownOpener handle={doctor.name} position="right">
                <ProfileMenu
                  name={doctor.name}
                  title={doctor.title}
                  onLogout={handleLogout}
                />
              </DropdownOpener>
            ) : null
          }
        />

        <motion.div
          key={page}
          layout
          className={clsx({ [classes.body]: true, [classes.splash]: splash })}
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 5 }}
        >
          {!splash && (
            <>
              <div className={classes.title}>
                {back && (
                  <Clickable className={classes.back} onClick={back}>
                    <Icon type="chevronLeft" />
                  </Clickable>
                )}
                {title}
              </div>
              <div className={classes.border} />
              {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
            </>
          )}

          <div className={classes.content}>{children}</div>
        </motion.div>

        <Footer>
          <Trans id="© Align Technology, Inc. All rights reserved." />{' '}
          {/* TODO: target="_blank?" */}
          <a href={privacyLink}>
            <Trans id="Privacy" />
          </a>{' '}
          {/* TODO: target="_blank?" */}
          <a href={termsOfUseLink}>
            <Trans id="Terms of Use" />
          </a>
        </Footer>
      </div>
    </CssBaseline>
  );
};

export default BasePageTemplate;
