import { createUseStyles } from 'react-jss';
import { heading05, iconSecondary, textSecondary } from '../../../theme';

export default createUseStyles(
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      transition: 'all 0.2s ease-in-out',
    },

    splash: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    back: {
      width: 32,
      height: 32,
      padding: 6,
      marginRight: 14,
      boxSizing: 'border-box',
      color: iconSecondary,
    },

    border: {
      display: 'none',
      marginTop: 24,
      marginBottom: 32,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
      ['@media screen and (max-width: 768px)']: {
        marginTop: 12,
        marginBottom: 12,
      },
      '$bordered &': {
        display: 'block',
      },

      '$collapsed &': {
        marginBottom: 0,
      },
    },

    collapsed: {},
    bordered: {},

    body: {
      padding: '50px 32px 0',
      flexGrow: 1,

      ['@media (min-width: 768px)']: {
        padding: '50px 80px 0',
      },
      ['@media (max-width: 768px)']: {
        padding: '20px 20px',
      },
    },

    title: {
      ...heading05,
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      ['@media screen and (max-width: 768px)']: {
        fontSize: '16px',
      },
    },

    content: {
      marginTop: 50,
      '$collapsed &, $splash &': {
        marginTop: 0,
      },
    },

    subtitle: {
      color: textSecondary,
      marginTop: 8,
    },
  },
  {
    name: 'BasePageTemplate',
  },
);
