import { Trans } from '@lingui/react';
import clsx from 'clsx';
import * as React from 'react';
import { Clickable, Icon } from '../../atoms';
import useStyles from './ProfileMenu.styles';

export interface IProfileMenuProps {
  name: React.ReactNode;
  title: React.ReactNode;
  onLogout(): void;
}

// TODO
// icons align
const ProfileMenu: React.FC<IProfileMenuProps> = ({
  name,
  title,
  onLogout,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={clsx(classes.item, classes.doctor)}>
        <div className={classes.name}>{name}</div>
        <div className={classes.title}>{title}</div>
      </div>

      <Clickable className={classes.item} onClick={onLogout}>
        <div className={classes.icon}>
          <Icon type="logout" />
        </div>
        <div className={classes.text}>
          <Trans id="Logout" />
        </div>
      </Clickable>
    </div>
  );
};

export default ProfileMenu;
