import * as React from 'react';
import { Clickable } from '../../atoms/Clickable';
import useStyles from './Header.styles';
import { Logo } from './Logo';
import { useRouter } from 'next/router';
import { useTheme } from 'react-jss';
import { CustomCiamUiTheme } from '../../../theme';
import {
  clientDisplayNameMap,
  IBusinessUnit,
} from '../../../utils/businessUnitMappingHelpers/businessUnitMappingHelpers';

export interface IHeaderProps {
  onLogosClick?(): void;
  profile?: React.ReactNode;
}

// TODO
// logo align
const Header: React.FC<IHeaderProps> = ({ onLogosClick, profile }) => {
  const theme = useTheme<CustomCiamUiTheme>();
  const classes = useStyles({ theme });
  const { query } = useRouter();
  const businessUnit = query.businessUnit;
  const displayName =
    clientDisplayNameMap[businessUnit as IBusinessUnit['type']];

  return (
    <div className={classes.root}>
      <Clickable className={classes.logos} onClick={onLogosClick}>
        <Logo />
        <div className={classes.partnerDisplayName}>{displayName}</div>
      </Clickable>
      <div className={classes.profile}>{profile}</div>
    </div>
  );
};

export default Header;
